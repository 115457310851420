import React from 'react'
// import { Link } from "gatsby"
// import Lottie from 'lottie-react-web'
import Layout from '../components/layout'
// import Image from "../components/image"
import SEO from '../components/seo'
// import SmartAnimationData from '../assets/animations/smart.json'
import FeatureSection from '../components/FeatureSection'
import SimpleFeature from '../components/SimpleFeature'
import Button from '../components/Button'

const BACKGROUND_FILL = '#FFFFFF'

const coffeeImages = [
  'https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?ixlib=rb-1.2.1&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1521401292936-0a2129a30b1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1522012188892-24beb302783d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1507133750040-4a8f57021571?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1510972527921-ce03766a1cf1?ixlib=rb-1.2.1&auto=format&fit=crop&w=540&q=80',
  'https://images.unsplash.com/photo-1485808191679-5f86510681a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=540&q=80'
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="bg-brand-primary">
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="flex flex-col items-start lg:w-1/3 xl:w-1/2 p-4 md:p-6 lg:px-10 lg:py-20">
          <p className="mb-2 max-w-lg lg:text-lg lg:max-w-xl uppercase font-body tracking-wide">
            The coffee Alley
          </p>
          <h1 className="mb-12 text-3xl md:text-4xl text-gray-900  font-display">
            Get premium cup of coffee, the simplest pleasure
          </h1>
          <Button label="Shop Now" withIcons />
        </div>
        <div className="relative lg:w-2/3 xl:w-1/2 mx-auto">
          <img
            className="m-0 md:-mt-48 lg:m-0 p-0"
            src={require('../assets/images/coffee-beans-in-cup.png')}
            alt="coffee-beans"
          />
        </div>
      </div>
    </section>

    <section className="bg-white mb-12 md:mb-24 xl:mb-32">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        className="md:-mt-10 lg:-mt-16 bg-brand-primary fill-current"
      >
        <path
          fill={BACKGROUND_FILL}
          d="M0,64L60,58.7C120,53,240,43,360,69.3C480,96,600,160,720,186.7C840,213,960,203,1080,186.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <div className="container mx-auto p-4 md:p-6 lg:px-10 flex flex-col md:flex-row items-center md:justify-center">
        <FeatureSection
          heading={`quality coffee`}
          body={`A place where the aromas of fresh made coffee and our delicious pastries, Paninis and healthy salads`}
          imageSrc={require('../assets/illustrations/coffee.svg')}
          imageOnRight={true}
        />
        <FeatureSection
          heading={`A place for coffee lover`}
          body={`the coffee alley was born to be more than a coffee shop,  as a place to hang out with friends and  family,  a home away from home`}
          imageSrc={require('../assets/illustrations/coffee_lover.svg')}
          imageOnRight={false}
          containerClass="md:mx-8 xl:mx-16"
        />
        <FeatureSection
          heading={`Enjoy coffee with free internet`}
          body={`We are passionate about coffee  and take it really seriously for you to enjoy it  with a smile Free Wireless Internet with your purchase!`}
          imageSrc={require('../assets/illustrations/coworking_.svg')}
          imageOnRight={true}
        />
      </div>
    </section>

    <section className="">
      <div className="container mx-auto">
        <SimpleFeature
          tag="the coffee alley"
          title="Quality coffee cultivated by good"
          body={`Our love for special coffee is never ending. We are committed to serve the best Chicago coffee, in the traditional European style`}
          imageSrc={require('../assets/images/coffee-bean-plant.png')}
        />
        <SimpleFeature
          tag="the coffee alley"
          title="Enjoy premium coffee"
          body={`We are passionate about coffee  and take it really seriously for you to enjoy it  with a smile Free Wireless Internet with your purchase!`}
          imageSrc={
            'https://images.unsplash.com/photo-1548428696-49620ac89d2c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80'
          }
          imageOnRight={true}
        />
        <SimpleFeature
          tag="the coffee alley"
          title="Best in-className service"
          body={`We offer a relaxing, inviting atmosphere that will transport you to another era, bringing together a great choice of food, special coffee and tea blends, along with great customer service`}
          imageSrc={
            'https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
          }
        />
      </div>
    </section>

    <section className="bg-brand-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        className="bg-white fill-current"
      >
        <path
          fill={'rgba(242, 239, 229, 1)'}
          d="M0,64L60,58.7C120,53,240,43,360,69.3C480,96,600,160,720,186.7C840,213,960,203,1080,186.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <div className="container mx-auto p-4 md:p-6 lg:px-10">
        <div className="p-4">
          <h3 className="mb-2 text-center text-xl uppercase tracking-widest font-display">
            #thecoffeealley
          </h3>
          <p className="text-sm text-gray-700 text-center font-body">
            Coffee perfection since 2012
          </p>
        </div>
        <div className="p-4 flex flex-wrap">
          {coffeeImages.map((url, index) => (
            <img
              className="w-full md:w-1/2 lg:w-1/3 h-56 lg:h-64 mb-2 object-cover"
              key={index}
              src={url}
              alt="coffee cup"
            />
          ))}
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
